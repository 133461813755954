<template>
  <header :style="{backgroundColor: headerStyle.backColor}" :class="{'is-top': !headerStyle.isTop}">
    <div class="contact-box">
      <img v-if="headerStyle.isTop" class="logo-img" @click="toPage('/')" :src="website.websiteMsg.logo" alt="">
      <img v-else class="logo-img" @click="toPage('/')" :src="website.websiteMsg.downLogo" alt="">
    </div>
    <nav>
      <div v-for="(item, index) in navList" :class="{'on':currentNav === item.url}" :key="index" @mouseover="navHover(item)" @mouseout="showDetails = false" @click="navClick(item)">
        <!-- <div class="text-box">
        </div> -->
        <span>{{ item.name }}</span>
        <div class="menu-details-box" v-if="item.zqNavVoList && item.zqNavVoList.length && isShow">
          <ul class="details-list">
            <li v-for="(det, detIndex) in item.zqNavVoList" @click.stop="toPage(det.url)" :key="detIndex">
              <div class="det-name">
                <h3>{{det.name}}</h3>
                <p>{{det.describe}}</p>
              </div>
              <ul class="other-list">
                <li v-for="(other, otherIndex) in det.zqNavVoList" :key="otherIndex" @click="toPage(other.url)">
                  <h3>{{other.name}}</h3>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script setup>
import { ref, reactive, computed, watch, toRef, onMounted, onBeforeUnmount } from 'vue'
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import resolveConfig from '@/views/solve/config'
import { getNavSelectAll } from '@/api/publish'
import scrollFun from './scroll'
import { useWebsite } from '@/pinia/index'
const website = useWebsite()
const props = defineProps({
  color: {
    type: String,
    default: "#fff"
  }
})

const { headerStyle, handleScroll } = scrollFun
const isShow = ref(true)

const showDetails = ref(false)
const resolveList = resolveConfig.map(el => {
  return {
    name: el.name,
    describe: el.introduce,
    url: `/solve/home?id=${el.id}`
  }
})

let color = ref('#fff')
const navList = ref([])
const currentNav = ref(navList.value.length ? navList.value[0].url : '')
const route = reactive(useRouter())
function toPage(url) {
  isShow.value = false
  setTimeout(() => {
    isShow.value = true
  }, 300)
  if (url.indexOf('http') !== -1) {
    window.oper(url)
    return false;
  }
  console.log(url)
  route.push(url)
}
function navClick(item) {
  if (item.url) {
    currentNav.value = item.url
    toPage(item.url)
  }
}
function navHover(item) {
  showDetails.value = true
}

getNavSelectAll().then(
  res => {
    navList.value = res.data
  }
)


// const headerStyle = reactive({
//   backColor: 'transparent'
// })
// function handleScroll() {
//   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
//   if (scrollTop > 100) {
//     headerStyle.backColor = '#fff'
//   } else if (scrollTop === 0) {
//     headerStyle.backColor = 'transparent'
//   }
// }
// onMounted(() => {
//   window.addEventListener('scroll', handleScroll)
// })

// onBeforeUnmount(() => {
//   window.removeEventListener('scroll', handleScroll)
// })

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

watch(route, (newval) => {
  currentNav.value = newval.currentRoute.path
  const list = ['/post/index', '/post/details', '/post/contact']
  if (list.includes(newval.currentRoute.path)) {
    color.value = "rgb(143, 101, 174)"
  } else {
    color.value = "#fff"
  }
}, {
  immediate: true
})
</script>
<style lang='scss' scoped>
@keyframes anim-height {
  from {
    height: 0;
  }
  to {
    height: 230px;
  }
}
// @import "@/styles/global.scss";
header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 0 calc(50% - 600px);
  min-width: 1200px;
  height: $headerHeight;
  color: #fff;
  transition: linear 0.3s;

  .logo-img {
    position: relative;
    top: 5px;
    z-index: 8;
    width: 144px;
  }
  nav {
    display: flex;
    line-height: $headerHeight;
    margin-left: 30px;
    > div {
      margin-left: 20px;
      padding: 0 10px;
      width: 100px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      > span {
        display: inline-block;
        line-height: 60px;
        border-bottom: 3px solid;
        border-color: transparent;
      }
      &:hover {
        font-size: 18px;
        > span {
          border-color: #fff;
        }
        .menu-details-box {
          display: block;
        }
      }
    }
    .on {
      > span {
        font-size: 18px;
        border-color: #fff;
      }
    }
  }
}
.is-top {
  box-shadow: 0 4px 15px rgb(0 0 0 / 20%);
  color: #333;
}
.contact-box {
  position: relative;
  cursor: pointer;
  &:hover {
    .gzh-box {
      animation: anim-height 1s;
      height: 230px;
    }
  }
  .gzh-box {
    position: absolute;
    top: 100px;
    z-index: -1;
    box-sizing: border-box;
    overflow: hidden;
    height: 0;
    font-size: 18px;
    text-align: center;
    background-color: #fff;
    > h3 {
      padding-top: 10px;
    }
    > img {
      width: 80%;
    }
    &::before {
      position: absolute;
      top: -96px;
      left: 50%;
      z-index: -3;
      width: 5px;
      height: 130px;
      transform: translateX(-50%);
      background-color: inherit;
      border-radius: 10px;
      content: "";
    }
  }
}
.menu-details-box {
  display: none;
  position: absolute;
  top: $headerHeight - 10px;
  left: calc(50% - 600px);
  box-sizing: border-box;
  padding: 30px;
  width: 1200px;
  background-color: #fff;
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.3);
  transition: all 1s;
  animation: opacityChange 1s;
  .details-list {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin-bottom: 25px;
      box-sizing: border-box;
      width: 50%;
      cursor: pointer;
      text-align: left;
      .det-name {
        padding: 20px 20px;
      }
      &:hover {
        // transition: linear 0.5s;
        .det-name {
          background-color: #0766bc;
          > h3 {
            color: #fff;
          }
          > p {
            color: #fff;
            opacity: 0.7;
          }
        }
      }
      .other-list {
        padding-top: 20px;
        > li {
          font-size: 16px;
          line-height: 1.5em;
          padding: 10px 0 20px 20px;
          color: #333;
          &:hover {
            color: $hoverColor;
          }
        }
      }
      .det-name {
        > h3 {
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 1.5em;
          font-weight: normal;
          color: #202020;
        }
        > p {
          margin-top: 5px;
          font-size: 14px;
          line-height: 1.5em;
          color: #808080;
        }
      }
    }
  }
}
</style>