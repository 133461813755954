<template>
  <footer>
    <section class="company-info">
      <div class="left-box">
        <img class="logo-img" @click="toPage('/')" :src="websiteMsg.logo" alt="">
        <div class="introduce">
          <p class="name">联系电话</p>
          <p class="details">{{websiteMsg.mobile}}</p>
          <p class="name">邮箱</p>
          <p class="details">{{websiteMsg.email}}</p>
          <p class="name">地址</p>
          <p class="details" style="width: 250px">{{websiteMsg.address}}</p>
        </div>
      </div>
      <div class="product-msg">
        <div class="msg-box" v-for="(item, index) in list" :key="index">
          <h3>{{item.name}}</h3>
          <ul v-if="item.zqTextImgList && item.zqTextImgList.length">
            <li v-for="(det, detIndex) in item.zqTextImgList" :key="detIndex" @click="toPage(det.url)">{{ det.name }}</li>
          </ul>
        </div>
      </div>
      <div class="gzh-box">
        <h3>关注我们</h3>
        <img :src="websiteMsg.weChatImg" alt="">
      </div>
    </section>
    <section class="copyright">
      <p>Copyright © {{websiteMsg.copyrightFrom}}</p>
      <p class="goCopy" @click="openBA(websiteMsg.recordNumberLink)">{{websiteMsg.recordNumber}}</p>
    </section>
  </footer>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getBottomGet, getPageBaseBottomVo } from '@/api/publish'
import { useWebsite } from '@/pinia/index'
import { storeToRefs } from 'pinia'
const route = reactive(useRouter())
function toPage(url) {
  route.push(url)
}
function openBA(url) {
  window.open(url)
}
const { websiteMsg } = storeToRefs(useWebsite())
const list = ref([
  {
    title: "关于知谦",
    data: [
      { name: "公司简介", url: "/nav/about" },
      { name: "加入我们", url: "/post/index" },
      { name: "联系我们", url: "/post/contact" },
    ]
  },
  {
    title: "产品服务",
    data: [
      { name: "海马链引擎", url: "/product/engine" },
      { name: "海马链BaaS平台", url: "/product/baas" },
      { name: "产品要素交易平台", url: "/product/trade" },
      { name: "产品溯源SaaS系统", url: "/product/source" },
      { name: "智慧电商SaaS平台", url: "/product/wisdom" },
    ]
  },
  {
    title: "解决方案",
    data: [
      { name: "供应链金融解决方案", url: "/solve/home?id=1" },
      { name: "应收账款管理解决方案", url: "/solve/home?id=2" },
      { name: "政务数据解决方案", url: "/solve/home?id=3" },
      { name: "区块链溯源解决方案", url: "/solve/home?id=5" },
      { name: "工业数据互信解决方案", url: "/solve/home?id=4" },
    ]
  }
])

// getBottomGet().then(res => {
//   websiteMsg.value = res.data;
//   website.websiteMsg = res.data
// })

getPageBaseBottomVo().then(res => {
  const newList = []
  Object.keys(res.data).forEach(key => {
    newList.push(res.data[key])
  })
  list.value = newList
})

</script>
<style lang='scss' scoped>
$split-line: 1px solid #eee;
@function randomNum($max, $min: 0, $u: 1) {
  @return ($min + random($max)) * $u;
}

@function randomConicGradient() {
  $n: 16 + random(16);
  $list: ();

  @for $i from 0 to $n {
    $list: $list,
      rgba(
        hsl(200, randomNum(250, 10, 10%), randomNum(70, 1, 1%)),
        randomNum(100, 0, 0.05)
      );
  }

  @return conic-gradient($list, nth($list, 1));
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
footer {
  position: relative;
  overflow: hidden;
  padding: 90px calc(50% - 600px) 32px;
  background-color: #14171c;
  font-size: 14px;
  color: #f5f5f5;
  // &:before,
  // &:after {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   margin: -100vmax;
  //   width: 200vmax;
  //   height: 200vmax;
  //   opacity: 0.5;
  //   mix-blend-mode: overlay;
  //   // animation: rotate randomNum(100, 25, 0.1s) ease-in-out infinite;
  //   content: "";
  // }

  &:before {
    background: randomConicGradient();
  }
  &:after {
    background: randomConicGradient();
    animation-duration: randomNum(100, 25, 0.1s);
    animation-direction: reverse;
  }
}
.company-info {
  display: flex;
  padding-bottom: 63px;
  border-bottom: $split-line;
}
.left-box {
  padding-right: 70px;
  margin-right: 80px;
  border-right: $split-line;
  .logo-img {
    margin-bottom: 35px;
    width: 220px;
  }
  .name {
    color: #aaaaaa;
  }
  .details {
    margin: 5px 0 17px;
    font-size: 18px;
    font-style: italic;
    user-select: all;
  }
}
.product-msg {
  flex: 1;
  display: flex;
  padding-right: 120px;
  .msg-box {
    flex: 1;
  }
  h3 {
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 23px;
    font-size: 18px;
    font-weight: normal;
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 26px;
      height: 2px;
      background-color: #fff;
      content: "";
    }
  }
  ul {
    li {
      margin-bottom: 14px;
      color: #aaaaaa;
      opacity: 0.8;
      cursor: pointer;
      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
  }
}
.copyright {
  padding-top: 27px;
  text-align: center;
  opacity: 0.8;
  > p {
    font-size: 14px;
    line-height: 2.3em;
  }
  .goCopy {
    cursor: pointer;
    &:hover {
      color: $hoverColor;
    }
  }
}
.gzh-box {
  > h3 {
    padding-bottom: 30px;
    font-size: 18px;
    font-weight: normal;
  }
  > img {
    width: 118px;
  }
}
</style>