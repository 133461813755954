import { defineStore } from 'pinia'
export const useWebsite = defineStore({
  id: 'website',
  state: () => ({
    websiteMsg: {}
  }),
  actions: {
  }
})

