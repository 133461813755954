<template>
  <section class="banner-box">
    <!-- <img class="banner-img" src="https://ayinoss2.oss-cn-beijing.aliyuncs.com/img/1652266347174qW7nO1Iu.png" alt=""> -->
    <el-carousel height="640px" v-if="bannerList.length">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div class="banner-text">
          <h3 class="banner-title" v-html="item.title"></h3>
          <p class="banner-desc over-text4" v-html="item.text"></p>
          <div class="custom-btn" v-if="item.buttonText" @click="toPage(item.url)">
            {{item.buttonText}} <el-icon size="18" style="position: relative; top:2px; margin-left: 5px;">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
        <img class="banner-img" :src="item.img" alt="">
      </el-carousel-item>
    </el-carousel>
  </section>
</template>
<script setup>
import { ref, reactive, computed, defineProps, toRefs } from 'vue'
import { queryBannerSelect } from '@/api/publish'
import { useRouter } from 'vue-router'
const router = useRouter()
function toPage(url) {
  if (url) {
    router.push(url)
  }
}
const props = defineProps({
  list: {
    type: Array,
    default: () => ([])
  },
  position: {
    type: Number,
    default: 1
  }
})
const { list, position } = toRefs(props)
const bannerList = ref([])

queryBannerSelect({
  page: 1,
  size: 10,
  position: position.value,
  type: 0
}).then(res => {
  bannerList.value = res.data.data
})

</script>
<style lang='scss' scoped>
.banner-box {
  height: 640px;
}
</style>