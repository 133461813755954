import { createStore } from 'vuex'
import getters from './getters'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
  getters,
})
