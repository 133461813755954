import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./styles/list.scss?vue&type=style&index=0&lang=scss"
import "./styles/animations.scss?vue&type=style&index=1&lang=scss"
import "./styles/mixin.scss?vue&type=style&index=2&lang=scss"
import "./App.vue?vue&type=style&index=3&id=10a82088&lang=scss"

const __exports__ = script;

export default __exports__