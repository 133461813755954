import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/Layout/index.vue'

const nav = {
  path: '/nav',
  component: layout,
  children: [
    {
      path: '/nav/home',
      name: 'home',
      component: () => import('@/views/nav/home.vue'),
      meta: {
        name: "首页"
      }
    },
    {
      path: '/nav/about',
      name: 'about',
      component: () => import('@/views/nav/about.vue'),
      meta: {
        name: "关于我们"
      }
    },
    {
      path: '/nav/case',
      name: 'case',
      component: () => import('@/views/nav/case.vue'),
      meta: {
        name: "客户和案例"
      }
    },
  ]
};

const cases =  {
  path: '/case',
  component: layout,
  children: [
    {
      path: 'details',
      name: 'caseDetails',
      component: () => import('@/views/case/details.vue'),
      meta: {
        name: "案例详情"
      }
    },
  ]
}

// 岗位
const post =  {
  path: '/post',
  component: layout,
  children: [
    {
      path: 'index',
      name: 'postHome',
      component: () => import('@/views/post/index.vue'),
      meta: {
        name: "加入我们"
      }
    },
    {
      path: 'details',
      name: 'postDetails',
      component: () => import('@/views/post/details.vue'),
      meta: {
        name: "岗位详情"
      }
    },
    {
      path: 'contact',
      name: 'postContact',
      component: () => import('@/views/post/contact.vue'),
      meta: {
        name: "联系我们"
      }
    },
  ]
}

const product = {
  path: '/product',
  component: layout,
  children: [
    {
      path: 'source',
      name: 'productSource',
      component: () => import('@/views/product/source.vue'),
      meta: {
        name: "海马链产品溯源SaaS平台"
      }
    },
    {
      path: 'trade',
      name: 'productTrade',
      component: () => import('@/views/product/trade.vue'),
      meta: {
        name: "海马链产业要素交易平台"
      }
    },
    {
      path: 'baas',
      name: 'productBaas',
      component: () => import('@/views/product/baas.vue'),
      meta: {
        name: "海马链Baas平台"
      }
    },
    {
      path: 'engine',
      name: 'productEngine',
      component: () => import('@/views/product/engine.vue'),
      meta: {
        name: "海马引擎链"
      }
    },
    {
      path: 'wisdom',
      name: 'productWisdom',
      component: () => import('@/views/product/wisdom.vue'),
      meta: {
        name: "海马链智慧电商SaaS平台"
      }
    },
  ]
}
const solve =  {
  path: '/solve',
  component: layout,
  children: [
    {
      path: 'home',
      name: 'solveHome',
      component: () => import('@/views/solve/index.vue'),
      meta: {
        name: "解决方案"
      }
    },
  ]
}

const routes = [
  {
    path: '/',
    redirect: '/nav/home',
  },
  nav,
  cases,
  product,
  solve,
  post,
]
const router = createRouter({
  history: createWebHashHistory(),
  mode: 'hash',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
