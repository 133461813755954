export default [
  {
    id: 1,
    name: "供应链金融解决方案",
    introduce: "链接企业资产端及金融机构资金端，助力惠普金融",
    bannerId: 8,
    banner: [
      {
        backImg: require("@/assets/solve/banner/backimg1.png"),
        img: require("@/assets/solve/banner/right1.png"),
        title: '供应链金融解决方案',
        desc: '利用海马链技术能力，同时结合物联网技术，将商品从原材料采购、加工、生产、质检、物流、经销、零售，一直到消费者等全流程信息可信记录，解决了信息孤岛、信息流转不畅、信息缺乏透明度等行业问题。',
        btnText: '联系我们',
        url: '/post/contact',
      }
    ],
    // type: left right img  对应三种类型
    details: [
      {
        type: "left",
        title: "行业痛点",
        img: require("@/assets/solve/data1-list1.png"),
        list: [
          {
            name: "融资难",
            desc: "由于供应链中的小微企业自身信用级别较低，固定资产等抵押担保品少、财务信息不透明等原因，难以获得银行等大型金融机构的授信。"
          },
          {
            name: "管理难",
            desc: "供应链由多方主体共同参与，容易造成信息不对称、不透明甚至传递过程的失真的现象，导致供应链运作效率低下，甚至引发风险。"
          },
          {
            name: "融资成本高",
            desc: "多级供应商通常面临较高的融资费率，民间借贷利率高，纠纷不断，一债多抵多卖现象难以杜绝。"
          },
        ]
      },
      {
        type: "right",
        title: "方案优势",
        img: require("@/assets/solve/data1-list2.png"),
        list: [
          {
            name: "强资产数字化",
            desc: "应收账款资产数字化，链上实现债权凭证的流转，保证资产不可篡改，不可重复融资，同时可被追溯。"
          },
          {
            name: "强供应链管理",
            desc: "核心企业以自身信用，协助解决上游供应商资金短缺问题，加强全链路管控，降低供应链风险。"
          },
          {
            name: "协同共建生态",
            desc: "协同核心企业及其上下游供应商、银行、保理等多种金融机构，共建基于区块链的供应链金融协作生态，解决供应商融资难问题，同时提升多方协作效率"
          },
        ]
      },
      {
        type: "img",
        title: "方案架构",
        img: require("@/assets/solve/data1-img.png"),
      },
    ]
  },
  {
    id: 2,
    name: "应收账款管理解决方案",
    introduce: "链接企业资产端及金融机构资金端，助力惠普金融",
    bannerId: 9,
    banner: [
      {
        backImg: require("@/assets/solve/banner/backimg2.png"),
        img: require("@/assets/solve/banner/right2.png"),
        title: '应收账款管理解决方案',
        desc: '构建企业和金融机构的联盟链，通过企业交易信息的加密共享，降低金融机构面临的信用风险',
        btnText: '联系我们',
        url: '/post/contact',
      }
    ],
    // type: left right img  对应三种类型
    details: [
      {
        type: "left",
        title: "行业痛点",
        img: require("@/assets/solve/data2-list1.png"),
        list: [
          {
            name: "安全性无法保障，企业担心信息外泄",
            desc: "由于供应链中的小微企业自身信用级别较低，固定资产等抵押担保品少、财务信息不透明等原因，难以获得银行等大型金融机构的授信。"
          },
          {
            name: "信息准确性无法验证，金融机构担心真实性",
            desc: "供应链由多方主体共同参与，容易造成信息不对称、不透明甚至传递过程的失真的现象，导致供应链运作效率低下，甚至引发风险。"
          },
          {
            name: "系统复杂，多方维护，成本较高",
            desc: "多级供应商通常面临较高的融资费率，民间借贷利率高，纠纷不断，一债多抵多卖现象难以杜绝。"
          },
          {
            name: "多次信息交互，业务流程较长",
            desc: "多级供应商通常面临较高的融资费率，民间借贷利率高，纠纷不断，一债多抵多卖现象难以杜绝。"
          },
        ]
      },
      {
        type: "right",
        title: "方案优势",
        img: require("@/assets/solve/data2-list2.png"),
        list: [
          {
            name: "零知识证明、保障企业信息安全",
            desc: "通过零知识证明的能力，可以让企业的业务数据在加密的状态下用于验证，保障企业信息安全，打消企业对数据上链的疑虑。"
          },
          {
            name: "分布式账本，保障交易真实性",
            desc: "通过区块链分布式账本的功能，保障上链数据不会被篡改，确保数据的真实性，帮助金融机构避免贷款中经常出现骗贷现象。"
          },
          {
            name: "基于原生技术搭建，部署快速",
            desc: "服务最快5分钟完成区块链部署时效性高，帮助金融机构快速开展业务。"
          },
          {
            name: "算法优化，满足时效性要求",
            desc: "开源Fabric的交易性能在几百tps，华为通过算法优化可以提升到10000tps，满足客户在时效性上的要求，支持业务快速扩张。"
          },
        ]
      },
      {
        type: "img",
        title: "方案架构",
        img: require("@/assets/solve/data2-img.png"),
      },
    ]
  },
  {
    id: 3,
    name: "政务数据解决方案",
    introduce: "通过区块链技术实现政企互联",
    bannerId: 10,
    banner: [
      {
        backImg: require("@/assets/solve/banner/backimg3.png"),
        img: require("@/assets/solve/banner/right3.png"),
        title: '政务数据解决方案',
        desc: '通过区块链技术解决政务数据使用监控、多部门多主体协同、数据安全加密的诉求，助力政务数据管理',
        btnText: '联系我们',
        url: '/post/contact',
      }
    ],
    // type: left right img  对应三种类型
    details: [
      {
        type: "left",
        title: "行业痛点",
        img: require("@/assets/solve/data3-list1.png"),
        list: [
          {
            name: "利益不共享",
            desc: "政务数据的使用常常涉及多主体，各方权责要清晰，多主体间的数据传输要有介质连接，跨体系数据传输需要有可靠的传输媒介。"
          },
          {
            name: "授权不完整",
            desc: "政务数据较敏感，为保证真实性，数据需经过官方授权与验证，数据的获取与使用需全流程监控，使用情况要做及时反馈。"
          },
          {
            name: "数据不脱敏",
            desc: "因数据敏感，储存时需保证信息安全，传输时需防止数据泄露，储存和传输的安全要求高。"
          },
        ]
      },
      {
        type: "right",
        title: "方案优势",
        img: require("@/assets/solve/data3-list2.png"),
        list: [
          {
            name: "数据价值可信流通",
            desc: "基于海马链可信区块链技术，无需数据的集中式汇总，实现数据确权和数据价值可信流转，确保多方数据主权共享共治。"
          },
          {
            name: "数据资源安全共享",
            desc: "结合数字身份、异构数据整合、数据隐私保护等基础技术能力，支持数据库表、数据接口、数据模型、数据文件的授权、调用等多种共享交换模式，通过国密算法满足数据全链路加密要求。"
          },
          {
            name: "数据操作流程追溯",
            desc: "对数据资源的授权、调用操作进行全过程上链，实现授权方、使用方、调用方的操作行为透明可监管，支持事后监管审计。"
          },
        ]
      },
      {
        type: "img",
        title: "方案架构",
        img: require("@/assets/solve/data3-img.png"),
      },
    ]
  },
  {
    id: 4,
    name: "工业数据互信解决方案",
    introduce: "推动工业数据要素化发展",
    bannerId: 11,
    banner: [
      {
        backImg: require("@/assets/solve/banner/backimg4.png"),
        img: require("@/assets/solve/banner/right4.png"),
        title: '工业数据互信解决方案',
        desc: '工业互联网数据互信交换系统基于海量数据汇聚存证服务体系，支撑跨领域、跨行业、跨企业边界的多形态工业数据互信、互享，充分发挥区块链可构建可信基础设施能力。基于区块链智能合约构建数据互信认证和交换体系，促进数据交换，让工业互联网数据各要素之间敢于交换、易于交换、乐于交换。工业互联网数据交换平台面向数据企业提供数据交易、数据变现和数据增值服务渠道。',
        btnText: '联系我们',
        url: '/post/contact',
      }
    ],
    // type: left right img  对应三种类型
    details: [
      {
        type: "left",
        title: "行业痛点",
        img: require("@/assets/solve/data1-list1.png"),
        list: [
          {
            name: "数据安全难以保证",
            desc: "数据是工业互联网核心生产要素，安全问题除了可能会出现数据文件的失窃和篡改的情况，攻击还会作用到现实生产中，直接影响到工业运行安全。"
          },
          {
            name: "数字资产难以确权",
            desc: "海量数据无法精确确定数据所有权，特别对于工业产业链条上的数据交易，如何科学规定数据属性，明码实价，通过统一规范的标准体系和共识机制实现数据共享、对接和交换，让数据有效集成，是当前需要攻克的难题。"
          },
          {
            name: "数据共享难以推动",
            desc: "数据共享与隐私保护的矛盾使得数据本身的价值不能被释放，数据分享后其稀缺性难以得到保证，隐私得不到保护使得数据所有者不愿、不敢、不能共享数据。"
          },
        ]
      },
      {
        type: "img",
        title: "方案架构",
        img: require("@/assets/solve/data4-img.png"),
      },
    ]
  },
  {
    id: 5,
    name: "区块链溯源解决方案",
    introduce: "基于区块链产业数字化转型利器",
    bannerId: 12,
    banner: [
      {
        backImg: require("@/assets/solve/banner/backimg5.png"),
        img: require("@/assets/solve/banner/right5.png"),
        title: '区块链溯源解决方案',
        desc: '利用区块链和物联网技术，解决了溯源信息的真实性问题。通过营销能力，驱动业务快速增长。拥有原产地真品、产业溯源、食品监管、供应链等溯源方案，适用于全领域溯源场景。',
        btnText: '联系我们',
        url: '/post/contact',
      }
    ],
    // type: left right img  对应三种类型
    details: [
      {
        type: "left",
        title: "行业痛点",
        img: require("@/assets/solve/data5-list1.png"),
        list: [
          {
            name: "商品防伪",
            desc: "商品经过“一物一码”标识，将全过程流转信息写入区块链。区块链上信息不能随意篡改，商品从生产、运输再到销售，每一个环节的信息都被记录在区块链上，可以确保商品的唯一性。造假商品很难具备合乎特定规则的商品标识。"
          },
          {
            name: "有效监管",
            desc: "商品从生产到销售，每一个环节的主体都以自己的身份（私钥）将信息签名写入区块链，信息不可篡改，身份不可抵赖。一旦出现纠纷，能够快速定位问题环节，进行举证和追责。"
          },
          {
            name: "供应链协同",
            desc: "区块链上的数据高效地在供应链不同部门之间进行共享，达到统一凭证、全程记录、及时高效，能够有效解决多方参与、信息碎片化、流通环节重复审核等问题，从而降低物流成本、提高效率。"
          },
          {
            name: "NFT确权",
            desc: "NFT（Non-Fungible Tokens）即非同质化代币，在区块链中代表现实世界的每一个商品（有形或无形），因区块链的技术特点，而具有唯一性、加密性，且NFT的所有权无法被篡改，也无法强制转移。因此是对商品确权的有效凭证。"
          },
        ]
      },
      {
        type: "img",
        title: "方案架构",
        img: require("@/assets/solve/data5-img.png"),
      },
    ]
  },
]