import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ArrowRight } from '@element-plus/icons'
import BannerStyle1 from '@/components/BannerStyle1'
import 'element-plus/dist/index.css'
import '@/utils/mobile'
const app = createApp(App)
app.component('ArrowRight', ArrowRight)
app.component('BannerStyle1', BannerStyle1)
app.use(ElementPlus).use(store).use(createPinia()).use(router).mount('#app')
