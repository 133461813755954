import { reactive, onMounted, onBeforeUnmount } from 'vue'
const headerStyle = reactive({
  backColor: 'transparent',
  isTop: true,
})
function handleScroll() {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 100) {
    headerStyle.backColor = '#fff'
    headerStyle.isTop = false
  } else if (scrollTop === 0) {
    headerStyle.backColor = 'transparent'
    headerStyle.isTop = true
  }
}
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

export default { handleScroll, headerStyle }